import TYPES from '@/types';
import { v4 } from 'uuid';
import Vue from 'vue';

// Application
import GetPersonalInfoQuery
  from '@/modules/flagship/investor-profile/personal-info/application/queries/get-personal-info-query';
import RetirementInvestorGoalCommand
  from '@/modules/flagship/retirement-investor-goal/application/commands/retirement-investor-goal-command';
import UpdateInvestorProfileCommand
  from '@/modules/flagship/investor-profile/investor-profile/application/commands/update-investor-profile-command';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import GetRetirementInvestorGoalQuery
  from '@/modules/flagship/retirement-investor-goal/application/queries/get-retirement-investor-goal-query';

// Domain
import {
  RetirementInvestorGoalEntity,
} from '@/modules/flagship/retirement-investor-goal/domain/entities/retirement-investor-goal-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class FlagshipProfilingStepRetirementFundViewModel {
  @Inject(TYPES.GET_PERSONAL_INFO_QUERY)
  private readonly get_personal_info_query!: GetPersonalInfoQuery;

  @Inject(TYPES.RETIREMENT_INVESTOR_GOAL_COMMAND)
  private readonly create_retirement_invest_command!: RetirementInvestorGoalCommand;

  @Inject(TYPES.UPDATE_INVESTOR_PROFILE_COMMAND)
  private readonly update_investor_profile_command!: UpdateInvestorProfileCommand;

  @Inject(TYPES.GET_RETIREMENT_INVESTOR_GOAL_QUERY)
  private readonly get_retirement_investor_goal_query!: GetRetirementInvestorGoalQuery;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view!: Vue;

  readonly i18n_namespace = 'components.flagship.flagship-profiling.flagship-profiling-step-retirement-fund';

  full_name = '';

  current_age = 0;

  retirement_age = 55;

  min_age_range = 55;

  readonly max_age_range = 75;

  initial_amount = '';

  investor_profile_id = '';

  has_retirement_fund: boolean | null = null;

  has_retirement_fund_options: Array<any> = [
    {
      label: '',
      value: true,
    },
    {
      label: '',
      value: false,
    },
  ];

  private retirement_investor_goal_entity: RetirementInvestorGoalEntity = {
    id: v4(),
    issued_age: 0,
    retirement_age_adjusted: 0,
    investor_goal: {
      investor_profile_id: '',
      initial_amount: 0,
    },
  }

  get is_disabled() {
    return this.has_retirement_fund === null || (this.has_retirement_fund === true
     && parseFloat(this.initial_amount.replace(/[^0-9.]/g, '')) <= 0);
  }

  public constructor(view: Vue) {
    this.view = view;
    this.has_retirement_fund_options[0].label = this.translate('yes');
    this.has_retirement_fund_options[1].label = this.translate('no');
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get personalInfo() {
    return this.get_personal_info_query.execute(this.investor_profile_id);
  }

  changeHasRetirementFund = () => {
    if (!this.has_retirement_fund) {
      this.initial_amount = '0';
    }
  }

  updateInformation = async () => {
    try {
      this.view.$emit('loadingStep', true);

      // eslint-disable-next-line max-len
      this.retirement_investor_goal_entity.investor_goal.investor_profile_id = this.investor_profile_id;
      // eslint-disable-next-line max-len
      this.retirement_investor_goal_entity.investor_goal.initial_amount = Number(parseFloat(this.initial_amount.replaceAll(/[^\d.-]/g, '')).toFixed(2));
      this.retirement_investor_goal_entity.issued_age = this.current_age;
      this.retirement_investor_goal_entity.retirement_age_adjusted = this.retirement_age;

      await this.create_retirement_invest_command.execute(this.retirement_investor_goal_entity);
      const investor_profile_update_data = {
        id: this.investor_profile_id,
        has_retirement_fund: this.has_retirement_fund || false,
      };
      await this.update_investor_profile_command.execute(investor_profile_update_data);

      this.view.$emit('nextStep');

      return true;
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.update_profile'),
      );
      return false;
    } finally {
      this.view.$emit('loadingStep', false);
    }
  }

  loadRetirementGoal = async () => {
    const retirement_goal = await this.get_retirement_investor_goal_query.execute();
    this.retirement_investor_goal_entity.issued_age = this.current_age;
    this.retirement_investor_goal_entity.retirement_age_adjusted = this.retirement_age;
    if (retirement_goal.investor_goal) {
      this.current_age = retirement_goal.issued_age;
      this.retirement_age = retirement_goal.retirement_age_adjusted;
      if (retirement_goal.investor_goal.initial_amount) {
        this.initial_amount = retirement_goal.investor_goal.initial_amount.toString();
      }
    }
  }

  loadInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute(false);
      this.investor_profile_id = investor_profile.id;
      this.has_retirement_fund = investor_profile.has_retirement_fund;
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.load_investor_profile'),
      );
    }
  }

  initialize = async () => {
    await this.loadInvestorProfile();
    await this.loadRetirementGoal();
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const { person } = await this.personalInfo;
    this.current_age = person.current_age;
    this.min_age_range = person.current_age > this.min_age_range
      ? this.current_age + 1 : this.min_age_range;
    this.full_name = `${person.name}`;
  }
}
