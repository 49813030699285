

































































































































import { Component, Vue } from 'vue-property-decorator';

import FlagshipProfilingStepRetirementFundViewModel
  from '@/vue-app/view-models/components/flagship/flagship-profiling/flagship-profiling-step-retirement-fund-view-model';

@Component({ name: 'FlagshipProfilingStepRetirementFund' })
export default class FlagshipProfilingStepRetirementFund extends Vue {
  flagship_about_you_view_model = Vue.observable(
    new FlagshipProfilingStepRetirementFundViewModel(this),
  );

  created() {
    this.flagship_about_you_view_model.initialize();
  }
}
